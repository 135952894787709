import React, { useState } from 'react';
import Month from './Month';

const ORIGINAL_MONTHS = 12

/*
const week_data = {
  weekno: 3,
  days: [1, 2, 3, 4, 5, 6, 7]
}
*/

function Calendar() {
  const [pastMonths, setPastMonths] = useState([])
  const [firstMonth, setFirstMonth] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth()
  })
  const [futureMonths, setFutureMonths] = useState([])

  let d = new Date()
  let months = [{
    year: d.getFullYear(),
    month: d.getMonth()
  }]
  d.setDate(1) // date to 1st of the month, so getMonth + 1 works
  
  // create current months
  for(let i = 0; i < ORIGINAL_MONTHS; i++) {
    d.setMonth(d.getMonth() + 1)
    const m = [{
      year: d.getFullYear(),
      month: d.getMonth()
    }]
    months = months.concat(m)
  }
  //console.log("months:", months)


  const createPastMonths = (count) => {
    let curr = pastMonths.length ? pastMonths[0] : firstMonth
    let d = new Date(curr.year, curr.month, 1)

    while(count--) {
      d.setMonth(d.getMonth() - 1)
      const newMonth = {
        year: d.getFullYear(),
        month: d.getMonth()
      }
      setPastMonths(pastMonths => [newMonth, ...pastMonths])
      setFirstMonth(newMonth)
    }
  }

  const createFutureMonths = (count) => {
    let curr = futureMonths.length ? futureMonths[futureMonths.length - 1] : months[months.length - 1]
    //console.log(`months.length: ${months.length}, months: ${months[months.length-1]} `)
    //console.log(`curr: ${curr}`)
    let d = new Date(curr.year, curr.month, 1)

    while(count--) {
      d.setMonth(d.getMonth() + 1)
      const newMonth = {
        year: d.getFullYear(),
        month: d.getMonth()
      }
      setFutureMonths(futureMonths => [...futureMonths, newMonth])
    }
  }

  const hidePastMonths = () => {
    const d = new Date()
    setFirstMonth({ year: d.getFullYear(), month: d.getMonth() })
    setPastMonths([])
  }

  const hideFutureMonths = () => {
    setFutureMonths([])
  }


  return (
    <div id="calendar-app" className="calendar-app">
      <div className="buttons">
        <button onClick={() => createPastMonths(2)}>Näytä 2 edellistä</button>
        <button onClick={() => createPastMonths(6)}>Näytä 6 edellistä</button>
        <button onClick={hidePastMonths}>Piilota menneet</button>
      </div>
      <div id="past-months">
        {
          pastMonths.map((m, ind) => (<Month key={ind} year={m.year} month={m.month} />))
        }
      </div>
      <div id="current-months">
      {
        months.map((m, ind) => (<Month key={ind} year={m.year} month={m.month} />))
      }
      </div>
      <div id="future-months">
        {
          futureMonths.map((m, ind) => (<Month key={ind} year={m.year} month={m.month} />))
        }
      </div>
      <div className="buttons-in-end">
        <button onClick={() => createFutureMonths(12)}>Näytä 12 seuraavaa</button>
        <button onClick={hideFutureMonths}>Piilota extra kuukaudet</button>
      </div>
    </div>
  );
}

export default Calendar;
