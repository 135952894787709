const HOLIDAYS = [
  ["2020", "1.1", "6.1", "10.4",  "13.4", "1.5", "21.5", "20.6",  "31.10", "6.12", "25.12", "26.12"],
  ["2021", "1.1", "6.1", "2.4",  "5.4", "1.5", "13.5", "26.6",  "6.11", "6.12", "25.12", "26.12"],
  ["2022", "1.1", "6.1", "15.4",  "18.4", "26.5", "25.6",  "5.11", "6.12", "25.12", "26.12"],
  ["2023", "1.1", "6.1", "7.4", "10.4", "1.5", "18.5", "24.6", "4.11", "6.12", "25.12", "26.12"],
  ["2024", "1.1", "6.1", "29.3", "1.4", "1.5", "9.5", "22.6", "2.11", "6.12", "25.12", "26.12"]
]

// date: year.month.day
// if current square is empty: day = 'null'
const Day = ({ date, holiday }) => {
  const dayNumber = date.split('.')
  const year = dayNumber[0]
  const month = dayNumber[1]
  const day = dayNumber[2]
  const today = new Date()
  let dayClass = "day"

  if(today.getFullYear() === (year*1) && today.getMonth()+1 === (month*1) && today.getDate() === (day*1)) { dayClass = "day today" }
  
  if(day === 'null') return ( <div className="day"></div> )


  for(let i = 0; i < HOLIDAYS.length; i++) {
    if(HOLIDAYS[i][0] !== year) continue;
    for(let j = 1; j < HOLIDAYS[i].length; j++) {
      const cells = HOLIDAYS[i][j].split('.') // cells[0] = day, cells[1] = month
      if(cells[1] === month && cells[0] === day) holiday = 'yes'
    }
  }

  return (
    <div className={dayClass} data-date={date} data-holiday={holiday}><div className="dayText">{day}</div></div>
  )
}

export default Day;