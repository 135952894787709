import Day from './Day'

/*
const data = {
  weekno: 3,
  days: [1, 2, 3, 4, 5, 6, 7]
}
*/
// if some days are null, squares will be empty
// e.g. days: [null, null, null, 1, 2, 3, 4]


const Week = ({ year, month, data }) => {
  return (
    <>
      <div className="week">{data.weekNo}</div>
      {
        data.days.map((element, index) => (
          <Day
            key={index}
            date={year + "." + month + "." + element}
            holiday={index === 6 ? "yes" : "no"}
          /> || console.log(`day: ${year}.${month}.${element}`)
        ))
      }

    </>
  )
}

export default Week;