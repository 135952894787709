import React from 'react';
import Week from "./Week";

const dayNames = ["", "Ma", "Ti", "Ke", "To", "Pe", "La", "Su"];

const Months = ["Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu",
"Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"];

/*
const week_data = {
  weekNo: 3,
  days: [null, null, 1, 2, 3, 4, 5]
}
*/


const getWeekNumber = (year, month, day) => {
  var d = new Date(year, month, day);
  d.setHours(0,0,0);
  d.setDate(d.getDate()+4-(d.getDay()||7));
  const weekNo = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
  
  return weekNo;
};

const createWeek = (first, last) => {
  const arr = []

  if(first === 1) {
    const firstWeekdayOfMonth = 7 - last // index of first day of the week. Array length will be 7
    //console.log("firstweekday:", firstWeekdayOfMonth)
    while(arr.length < firstWeekdayOfMonth) { arr.push(null) } // add empty days in front if needed
  }

  while(first <= last) arr.push(first++)
  while(arr.length < 7) arr.push(null) // add empty days to end of the week if needed

  //console.log("week_arr:", arr)
  return arr
}


const Month = ({ year, month }) => {
  let weekNo
  let weeks = []

  const d = new Date(year, month, 1);
  d.setMonth(d.getMonth() + 1)  // set to start of next month
  d.setDate(0)                  // set to last day of given month
  //console.log(`on Month.js: year: ${year}, month: ${month}, date:`, d.getDate())
  const lastDayOfMonth = d.getDate()
  //console.log(`lastday of ${Months[month-1]}:`, lastDayOfMonth)
  let firstDayOfWeek = 1
  let weekStarts = new Date(year, month, 1).getDay() // which day of the week month starts
  if(weekStarts === 0) weekStarts = 7           // case of first day of the month is sunday
  let lastDayOfWeek = 8 - weekStarts
  //console.log("weekStarts at:", dayNames[weekStarts])
  

  while(firstDayOfWeek <= lastDayOfMonth) {
    //console.log("lastdayofmonth:", lastDayOfMonth)
    //console.log("firstDayOfWeek:", firstDayOfWeek, "lastdayofweek:", lastDayOfWeek)
    weekNo = getWeekNumber(year, month, firstDayOfWeek)
    const week_arr = createWeek(firstDayOfWeek, lastDayOfWeek) // e.g. [null, 1, 2, 3, 4, 5, 6]
    const week_new = {
      weekNo: weekNo,
      days: week_arr
    }
    //console.log("whole data:", week_new)
    weeks = weeks.concat(week_new)
    //console.log("data added:", weeks)

    firstDayOfWeek = lastDayOfWeek + 1
    lastDayOfWeek = firstDayOfWeek + 6
    if(lastDayOfWeek > lastDayOfMonth) lastDayOfWeek = lastDayOfMonth
  }

  return (
    <div id={`${year}-${month+1}`}>
      <h5 className="monthHeader">{Months[month]} {year}</h5>
      <div className="calendar">
        {dayNames.map((elem, index) => (<div className="m-header" key={index}>{elem}</div>))}
        {weeks.map((week, index) => (<Week key={index} year={year} month={month+1} data={week} />))}
      </div>
    </div>
  )
}

export default Month;