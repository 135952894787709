import ReactDOM from 'react-dom';
import Calendar from './components/Calendar';
import './components/index.css'


ReactDOM.render(
  <Calendar />,
  document.getElementById('root')
);

